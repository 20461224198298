.Application {
  padding: 10px;
  button {
    display: block;
    margin-right: auto;
    margin-bottom: 10px;
  }
  .cards {
    display: flex;
    flex-wrap: wrap;
  }
  .card {
    text-align: start;
    width: 400px;
    margin-bottom: 15px;
    margin-right: 10px;
    min-width: 300px;
    padding: 20px 20px 0 20px;
    .card-title {
      text-align: center;
    }
  }
}
