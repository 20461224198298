.Applications {
  padding: 10px;
  button {
    display: block;
    margin-right: auto;
    margin-bottom: 10px;
  }
  .cards {
    display: flex;
  }
  .card {
    width: 300px;
    padding: 15px;
    margin-right: 10px;
    &:hover {
      opacity: 0.8;
    }
  }
}
