.item-label {
    color: var(--info);
}

.language-row {
    margin: 1rem 0;
}

.language-row.different {
    color: var(--danger);
}

.term-diff {
    margin: 2rem 0;
    border: 1px solid var(--secondary);
    padding: 1rem;
    font-size: 0.8rem;
}

.translation-language {
    color: var(--gray-dark);
}
