@import "~bootstrap/scss/bootstrap";

.App {
  text-align: center;
  height: 100%;
  .nav-container {
    height: 100%;
    display: flex;
  }
  .nav {
    width: fit-content;
    border-right: 2px solid gray;
    .nav-link {
      padding: 20px 30px;
    }
  }
  .content {
    width: 100%;
  }
  .main-content {
    padding: 2rem;
  }
}
