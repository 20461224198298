.TranslationPacksSelectForVersion {
  padding: 10px;
  display: flex;
  flex-direction: column;

  .createPack {
    display: block;
    margin-left: auto;
    margin-bottom: 20px;
  }
  .cards {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
  }
  
  .card {
    text-align: start;
    width: 400px;
    margin-bottom: 15px;
    margin-right: 10px;
    min-width: 300px;
    padding: 20px 30px;
  
    .card-title {
      text-align: center;
    }
  
    &:hover {
      opacity: 1.0;
      cursor: pointer;
      color: var(--primary);
    }
  }
  
}
