.Versions {
  padding: 10px;
  .cards {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  .card {
    text-align: start;
    width: 400px;
    margin-bottom: 15px;
    margin-right: 10px;
    min-width: 300px;
    padding: 20px 30px;

    .card-title {
      text-align: center;
    }

    &:hover {
      opacity: 0.8;
      cursor: pointer;
      color: white;
      background-color: var(--primary);
    }
  }

}
