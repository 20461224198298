.TranslationPacksSelect {
  padding: 10px;
  display: flex;
  flex-direction: column;
  .cards {
    display: flex;
    flex-wrap: wrap;
  }
  .card {
    text-align: start;
    width: 400px;
    margin-bottom: 15px;
    margin-right: 10px;
    min-width: 300px;
    padding: 20px 30px;
    .card-title {
      text-align: center;
    }
    &:hover {
      opacity: 0.8;
    }
  }
  .createPack {
    display: block;
    margin-left: auto;
    margin-bottom: 20px;
  }
}
