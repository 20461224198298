.Releases {
  padding: 10px;
  .content {
    display: flex;
    flex-direction: column;
    .btn {
      width: fit-content;
      margin-bottom: 10px;
    }
  }
}
